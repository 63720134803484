<template>
  <div class="mx-auto">
    <div class="spinner-grow purple" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow green" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <img
      src="https://raw.githubusercontent.com/openjusticebe/ui-assets/main/svg/oj-animate.svg"
      alt="Loading..."
      width="200"
    />

    <div class="spinner-grow green" role="status">
      <span class="sr-only">Loading...</span>
    </div>

    <div class="spinner-grow purple" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<style>
.purple {
  background-color: #5b397e;
}
.green {
  background-color: #2ab3a8;
}
</style>